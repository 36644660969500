import { render, staticRenderFns } from "./MainPlant.vue?vue&type=template&id=34cfa6e8&scoped=true&"
import script from "./MainPlant.vue?vue&type=script&lang=js&"
export * from "./MainPlant.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34cfa6e8",
  null
  
)

export default component.exports