<template>
  <div id="summary">
    summary
  </div>
</template>

<script>
  export default {
    name: 'summary'
  }
</script>

<style scoped>
  
</style>